<template>
    <div class="dateTimeContainer hide-scrollbar" :class="[{ portrait: ['portrait'].includes($mq) }, { changePrintDate: changePrintDate }]">
        <div class="print-date">
            {{ $t('label.preview.print_date') }}
        </div>
        <div class="print-date-text">
            {{ value }}
        </div>
        <div class="select-container">
            <div class="item date" @click="bShowCalendar = true" :class="{ selected: bShowCalendar }">{{ $t('audit.headers.date') }}</div>
            <div class="item hour" @click="bShowCalendar = false" :class="{ selected: !bShowCalendar }">{{ $tc('assets.timing.hour', 1) }}</div>
        </div>
        <div class="datePicker" v-if="bShowCalendar">
            <CalendarSelector :givenValue="date" ref="calendarSelector" @input="updateDate($event)"></CalendarSelector>
        </div>

        <div class="timePicker" v-else>
            <HourTimeSelector :show="true" :givenValue="time" ref="hourTimeSelector" @input="updateTime($event)"></HourTimeSelector>
        </div>
        <div class="footer">
            <button @click="cancel()" class="act cancel">{{ $t('label.print.cancel') }}</button>
            <button class="act save" @click="emitChanges()">{{ $t('popup.access.text_save') }}</button>
        </div>
    </div>
</template>

<script>
import HourTimeSelector from '@/components/inputs/HourTimeSelector'
import CalendarSelector from '@/components/inputs/CalendarSelector'

export default {
    name: 'dateTimePortrait',
    components: { HourTimeSelector, CalendarSelector },
    props: {
        date: {
            type: String
        },
        time: {
            type: String
        },
        value: {
            type: String
        },
        changePrintDate: {
            type: Boolean
        }
    },
    data() {
        return {
            newDate: false,
            newTime: false,
            bShowCalendar: true
        }
    },
    computed: {},
    methods: {
        getData() {
            return {
                date: this.newDate ? moment(this.newDate, 'DD/MM/YYYY').format('DD/MM/YYYY') : this.date,
                time: this.newTime ? this.newTime : this.time,
                oldDate: this.date,
                oldTime: this.time
            }
        },
        cancel() {
            this.$emit('cancel')
        },
        emitChanges() {
            this.$emit('updatePrintDate', { date: this.newDate ? this.newDate : this.date, time: this.newTime ? this.newTime : this.time })
            this.cancel()
        },
        updateDate($event) {
            this.newDate = moment($event, 'YYYY/MM/DD').format('DD/MM/YYYY')
        },
        updateTime($event) {
            this.newTime = $event
        }
        // met() {
        //     const datetime = `${this.date} ${this.time}`
        //     self.print_date = moment(datetime, 'DD/MM/YYYY HH:mm')
        //     // if print date was modified, show alert, if is first time, validate if new date and time are different from old date and time
        //     self.bUpdatedPrintDate = self.bUpdatedPrintDate || (!self.bUpdatedPrintDate && (popupData.date != popupData.oldDate || popupData.time != popupData.oldTime))
        //     self.bUpdatedPrintDateVisible = self.bUpdatedPrintDate

        //     //UPDATE DATE CALENDAR RANGS
        //     for (var k in self.data) {
        //         var step = self.data[k]
        //         if (typeof step.minDate !== 'undefined' && typeof step.maxDate !== 'undefined') {
        //             var minDate = undefined
        //             var maxDate = self.print_date.format('DD/MM/YYYY')

        //             if (step.keyname == 'dflevedacao') {
        //                 var auxDate = moment(self.print_date.format('YYYY-MM-DD HH:mm:ss'))
        //                 minDate = auxDate.subtract(4, 'd').format('DD/MM/YYYY')
        //                 auxDate = moment(self.print_date.format('YYYY-MM-DD HH:mm:ss'))
        //                 maxDate = auxDate.subtract(2, 'd').format('DD/MM/YYYY')
        //             } else if (step.keyname == 'dftpambiente') {
        //                 var auxDate = moment(self.print_date.format('YYYY-MM-DD HH:mm:ss'))
        //                 minDate = auxDate.subtract(6, 'd').format('DD/MM/YYYY')
        //                 auxDate = moment(self.print_date.format('YYYY-MM-DD HH:mm:ss'))
        //                 maxDate = auxDate.subtract(2, 'd').format('DD/MM/YYYY')
        //             }

        //             step.minDate = minDate
        //             step.maxDate = maxDate
        //             console.error(step)
        //             self.data[k] = step
        //         }
        //     }
        // }
    },
    mounted() {},
    created() {}
}
</script>

<style lang="scss">
.dateTimeContainer {
    &.portrait {
        position: absolute;
        top: 80px;
        @include display-flex();
        // @include justify-content(space-around);
        @include align-items();
        @include flex-direction(column);
        // flex-wrap: nowrap;
        min-height: 300px;
        height: calc(100% - 75px);
        width: 100%;
        background-color: $default-bg-color;
        padding: 10px 0px;
        z-index: 3;

        .print-date {
            @include font-size(m);
            font-family: $text-sbold;
            color: $main;
            width: 100%;
            text-align: center;
        }
        .print-date-text {
            color: $neutro-s80;
            @include font-size(ml);
            font-family: $text-bold;
            padding-top: 10px;
            padding-bottom: 20px;
        }

        .select-container {
            @include display-flex();
            @include align-items();
            @include flex-direction(row);
            width: 100%;
            padding-bottom: 10px;

            .item {
                @include font-size(s);
                font-family: $text-sbold;
                text-transform: uppercase;
                cursor: pointer;
                @include display-flex();
                @include align-items(center);
                @include justify-content(center);
                width: 100%;
                background-color: #fff;
                height: 45px;
                color: $neutro-s20;

                &.pending {
                    @include background($image: img('alert_complem.svg'), $size: 15px, $position: left 15px center);
                    background-color: #fff;
                }
                &.selected {
                    font-family: $text-sbold;
                    background-color: $neutro;
                    color: $main-dark;
                }
            }
        }

        .datePicker {
            @include display-flex();
            @include justify-content();
            @include align-items();
            @include flex-wrap(wrap);
            height: 100%;
            width: auto;
            max-width: 50%;
            padding: 0 15px;
            min-width: 350px;

            .calendar-container {
                width: 100%;
                height: 100%;
                margin-top: 0px;

                .vfc-styles-conditional-class {
                    @include display-flex();
                    @include align-items(center);
                    width: 100%;
                    height: 100%;

                    .vfc-main-container {
                        @include border-radius(8px);
                        width: 100%;
                        height: auto !important;
                        padding: 15px;

                        .vfc-calendars {
                            width: 100%;
                            height: 100%;
                            background-color: #fff;

                            .vfc-content {
                                background-color: #fff;
                                width: 100%;
                                height: auto;
                                margin: 0 !important;

                                .vfc-marked {
                                    background-color: $main-dark !important;
                                }

                                .vfc-top-date {
                                    position: relative;
                                    top: -8px;
                                }
                            }
                        }

                        .vfc-navigation-buttons {
                            height: 35px;
                            .vfc-cursor-pointer {
                                width: 35px;
                                height: 35px;
                                background-color: #eeeeee;

                                .vfc-arrow-right {
                                    top: -8px;
                                    right: 1px;
                                }
                                .vfc-arrow-left {
                                    top: -8px;
                                    left: 4px;
                                }
                                .vfc-disabled {
                                    top: 0px;
                                }
                            }
                        }
                    }
                }
            }
        }

        .timePicker {
            width: auto;
            max-width: 50%;
            height: 100%;
            padding: 0 15px;
            display: contents;
            background-color: #fff !important;
        }
        .footer {
            @include display-flex();
            @include justify-content(space-around);
            @include align-items(center);
            width: 100%;
            height: 50px;

            .act {
                @include border-radius(4px);
                padding: 12.5px 35px;
                font-family: $text-bold;
                &.cancel {
                    background-color: #e3e7ed;
                    color: $main;
                }
                &.save {
                    background-color: $main-dark;
                    color: #fff;

                    &.disabled {
                        opacity: 0.3;
                        cursor: not-allowed;
                    }
                }
            }
        }

        &.changePrintDate {
            height: auto;
            top: 70px;
            z-index: 4;
            left: 0;
            width: calc(100%);
            padding: 0 15px;

            .datePicker {
                padding-bottom: 80px;
            }

            .footer {
                @include background-gradient(70%, $default-bg-color);
                position: fixed;
                bottom: 0;
                padding-bottom: 15px;
                left: 0;
                z-index: 101;
                .act {
                    width: calc(50% - 20px) !important;

                    &.cancel {
                    }
                    &.save {
                        &.disabled {
                        }
                    }
                }
            }
        }
    }
}
</style>
