<template>
    <div :class="$mq" class="queue-view">
        <div class="top-menu">
            <div class="return-button" @click="back()">
                {{ $t('menu_aux.action.back') }}
            </div>
        </div>

        <Message v-if="!is_printing && printerError" :message="printerError" :loader="checkingStatusPrinter" :buttonLabel="$t('config.printer.validate')" @buttonAction="validatePrinter" type="error"></Message>

        <template v-if="is_printing && queue_printing.length > 0">
            <div class="loader">
                <div class="loader-box">
                    <div class="andy"></div>
                    <div class="loader"></div>
                    <div class="printer"></div>
                </div>
                {{ $t('label.queue.printing') }}
            </div>

            <div class="queue-container is_printing">
                <div class="labels-box hide-scrollbar">
                    <singleLabel v-for="label in queue_printing" :label="label" :is_printing="is_printing" :key="label.id" @discard="discardLabel(label.id)"></singleLabel>
                </div>
            </div>
        </template>

        <template v-if="queue_pending.length > 0">
            <div class="queue-container">
                <div class="label">{{ $t('label.queue.tags_pending') }}</div>
                <div class="labels-box hide-scrollbar">
                    <singleLabel v-for="label in queue_pending" :label="label" :is_printing="is_printing" :key="label.id" @discard="discardLabel(label.id)"></singleLabel>
                </div>
            </div>

            <div class="buttons" v-if="!is_printing">
                <div class="button discard" @click="discardAllLabels()">
                    <div class="text">{{ $t('label.queue.discard') }}</div>
                </div>
                <div class="button print" @click="print()">
                    <div class="text">{{ $t('label.popup.text_save') }}</div>
                </div>
            </div>
        </template>
    </div>
</template>

<script>
import singleLabel from '@/components/domain/label/singleLabel.vue'
import Message from '@/components/domain/ui/Message'
import i18n from '@/i18n'

export default {
    name: 'printQueue',
    components: { singleLabel, Message },

    props: {
        printerError: { type: [String, Boolean], default: false }
    },
    data() {
        return {}
    },
    computed: {
        labels() {
            return this.$store.getters['label/getLabelsOrdered']
        },

        queue() {
            var data = this.$store.getters['label/getPrintLogsAndroid']
            if (Object.keys(data).length == 0) {
                this.back()
            }
            return data
        },
        queue_printing() {
            var printing = []
            for (var index in this.queue) {
                var item = this.queue[index]
                if (this.is_printing && item.status == 1) printing.push(item)
            }
            return printing
        },
        queue_pending() {
            var pending = []
            for (var index in this.queue) {
                var item = this.queue[index]
                if (!this.is_printing || item.status != 1) pending.push(item)
            }
            return pending
        },

        is_printing() {
            return this.$store.getters['label/getIsPrinting']
        },

        checkingStatusPrinter() {
            return this.$store.getters['printer/isCheckingStatusPrinter']
        }
    },
    methods: {
        validatePrinter() {
            if (this.printerError == i18n.t('config.printer.errors.bpac_no_installed')) {
                window.open('https://chrome.google.com/webstore/detail/ilpghlfadkjifilabejhhijpfphfcfhb', '_blank')
            }
            this.$emit('initStatusPrinter')
        },

        labelQueue() {
            this.$store.dispatch('label/getPrintLogsAndroid')
        },

        print() {
            var self = this

            var copyQueue = JSON.parse(JSON.stringify(this.queue))

            this.$store
                .dispatch('printer/printQueueLabels', this.queue)
                .then(response => {
                    let numberOfCopies = 0

                    for (let index in copyQueue) {
                        numberOfCopies += parseInt(copyQueue[index].quantity)
                    }

                    let msg = i18n.tc('config.printer.success_queue_print', numberOfCopies)
                    msg = msg.replace('[num_copies]', numberOfCopies)

                    self.$snackbar.progress({
                        closeable: true,
                        message: msg
                    })
                    self.labelQueue()
                    // self.$snackbar.progress({
                    //     closeable: true,
                    //     message: self.$tc('label.preview.printing', Object.keys(this.queue).length)
                    // })
                })
                .catch(err => {
                    self.$snackbar.error({ closeable: true, message: i18n.t(err.error) })
                    const appVersion = process.env.VUE_APP_VERSION
                    self.$store.dispatch('label/addPrintError', {
                        error: self.printerError,
                        platform: 1,
                        version: appVersion
                    })
                    self.labelQueue()
                })
        },

        discardLabel(iLabel) {
            var self = this

            this.$popup.confirm({
                message: self.$t('label.queue.discard_popup', { s: self.labels[self.queue[iLabel].product].name }),
                title: self.$t('popup.confirm.title'),
                textSave: self.$t('label.queue.discard'),
                textCancel: self.$t('menu_aux.action.cancel'),
                callSave: function() {
                    self.$store.dispatch('label/removePrintLabelLogAndroid', iLabel.toString()).then(function() {
                        self.labelQueue()
                    })
                }
            })
        },

        discardAllLabels() {
            var self = this

            this.$popup.confirm({
                message: self.$t('label.queue.discard_all_popup'),
                title: self.$t('popup.confirm.title'),
                textSave: self.$t('label.queue.discard'),
                textCancel: self.$t('menu_aux.action.cancel'),
                callSave: function() {
                    self.$store.dispatch('label/clearPrintLabelLog').then(function() {
                        self.labelQueue()
                    })
                }
            })
        },

        back() {
            this.$emit('back')
        }
    },
    created() {
        this.labelQueue()
        this.$bar.reset()
        this.$bar.hide()
    },
    watch: {}
}
</script>

<style lang="scss" scoped>
.queue-view {
    @include display-flex();
    @include flex-direction(column);
    width: 100%;
    height: 100%;
    padding: 12px;

    .top-menu {
        @include display-flex();
        @include justify-content(space-between);
        padding: 5px;
        width: 100%;
        height: 55px;

        .return-button {
            @include border-radius(4px);
            @include background(
                $image: img('left_dark.svg'),
                $color: #fff,
                $position: (
                    left 15px center
                ),
                $size: 18px
            );
            @include font-size(s);
            font-family: $text-bold;
            background-color: #fff;
            width: fit-content;
            height: 45px;
            line-height: 45px;
            padding: 0 25px;
            padding-left: rem(45px);
            color: $neutro-s80;
            cursor: pointer;
        }
    }

    .loader {
        text-align: center;
        margin: 50px 0;

        .loader-box {
            @include display-flex();
            @include justify-content(center);
            @include align-items();
            width: 100%;
            height: auto;
            padding-bottom: 50px;

            .andy,
            .loader,
            .printer {
                height: 80px;
                width: 80px;
                margin: 0 10px;
            }
            .andy {
                @include font-size(s);
                @include background($image: img('alex_carita_10.svg'), $size: 80px);
            }
            .loader {
                width: 100px;
                @include background($image: img('loader_main.svg'), $size: 80px);
            }
            .printer {
                @include background($image: img('printer.svg'), $size: 80px);
            }
        }
    }

    .queue-container {
        overflow: auto;
        height: calc(100% - 60px);

        .label {
            @include display-flex();
            @include align-items();
            @include justify-content();
            @include font-size(ml);
            font-family: $text-bold;
            height: 55px;
            width: 100%;
            text-align: center;
        }

        .labels-box {
            height: calc(100% - 60px);
            overflow: auto;
            padding: 10px 20px;
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            align-content: start;
            column-gap: 15px;
            row-gap: 15px;
            align-items: start;

            @media (max-width: 1200px) {
                grid-template-columns: 1fr 1fr;
            }
        }

        &.is_printing {
            height: calc(100%);

            .labels-box {
                height: calc(100%);
            }
        }
    }

    .buttons {
        @include display-flex();
        @include align-items();
        @include justify-content(space-around);
        height: 60px;
        width: 100%;

        .button {
            @include display-flex();
            @include align-items();
            @include justify-content();
            @include border-radius(4px);
            max-width: 300px;
            width: calc(50% - 20px);
            height: 55px;
            padding: 10px;
            cursor: pointer;

            .text {
                font-family: $text-bold;
                color: white;
                padding-left: 30px;
            }

            &.discard {
                background-color: $error-dark;
                .text {
                    @include background(
                        $image: img('close_main_ffffff.svg'),
                        $position: (
                            left center
                        ),
                        $size: 18px
                    );
                }
            }

            &.print {
                background-color: $main;
                .text {
                    @include background(
                        $image: img('printer_ffffff.svg'),
                        $position: (
                            left center
                        ),
                        $size: 18px
                    );
                }
            }
        }
    }
}
</style>

// PORTRAIT STYLES
<style lang="scss" scoped>
.queue-view.portrait {
    .top-menu {
        .return-button {
            @include font-size(xs);
        }
    }

    .queue-container {
        .label {
            @include font-size(sm);
        }

        .labels-box {
            grid-template-columns: 1fr;
            column-gap: 15px;
            row-gap: 15px;
            padding: 10px 0;
        }
    }
}
</style>
