import { render, staticRenderFns } from "./LabelResume.vue?vue&type=template&id=39ad6c6c&scoped=true&"
import script from "./LabelResume.vue?vue&type=script&lang=js&"
export * from "./LabelResume.vue?vue&type=script&lang=js&"
import style0 from "./LabelResume.vue?vue&type=style&index=0&id=39ad6c6c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "39ad6c6c",
  null
  
)

export default component.exports